module.exports = new Promise(resolve => {
    const dynamicUrl = window?.__CONFIG_ENV_VALUES__?.microFrontEnds?.['r2vl']; // dynamic env override
    const configUrl = ({"development":"https://r2vl-qa.uidev.west2.mountain.com","devtest":"https://r2vl-devtest.uidev.west2.mountain.com","production-beta":"https://r2vl-qa.uidev.west2.mountain.com","production":"https://r2vl.uiprod.west2.mountain.com","qa-1":"https://r2vl-qa.uidev.west2.mountain.com","qa-2":"https://r2vl-qa.uidev.west2.mountain.com","qa-3":"https://r2vl-qa.uidev.west2.mountain.com","qa-automation":"https://r2vl-qa.uidev.west2.mountain.com","qa-beta":"https://r2vl-qa.uidev.west2.mountain.com","qa-rum-shapi2":"https://r2vl-qa.uidev.west2.mountain.com","qa-rum":"https://r2vl-qa.uidev.west2.mountain.com","qa-sr":"https://r2vl-qa.uidev.west2.mountain.com","qa":"https://r2vl-qa.uidev.west2.mountain.com","staging":"https://r2vl-qa.uidev.west2.mountain.com"})?.[window?.__CONFIG_ENV_VALUES__?.env];
    const base = dynamicUrl || configUrl;
    if (!base) throw new Error('No url set for r2vl');
    const remote = base + '/remoteEntry.js';
    const script = document.createElement('script');
    script.src = remote;
    script.onload = () => resolve({
      get: request => {
        try {
          return window.r2vl.get(request);
        } catch {
          console.error('Error with r2vl module');
        }
      },
      init: arg => {
        try {
          return window.r2vl.init(arg);
        } catch {
          console.error('Error with r2vl module');
        }
      }
    });
    document.head.appendChild(script);
  });